export const loadStyleSheet = (stylePath: string, callback?: () => void) => {
  const head = document.head
  const link = document.createElement('link')

  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = stylePath

  link.onload = () => {
    if (callback) {
      callback()
    }
  }

  head.appendChild(link)

  return () => {
    head.removeChild(link)
  }
}
